html,body,#root{
    height:100%;
    min-height:100%;
}
.hosting-about{
  background-color:aliceblue;
}
.hosting-grade-out{
  
  background: linear-gradient(0deg, rgba(52,201,235,1) 0%, rgba(240,248,255,1) 0%, rgba(52,201,235,1) 6%);

}
.hosting-grade-in{
background: linear-gradient(0deg, rgba(52,201,235,1) 0%, rgba(52,201,235,1) 0%, rgba(240,248,255,1) 100%);
  height: 40px
}
.fade-in-white{
  background: linear-gradient(0deg, rgba(240,248,255,1) 0%, rgba(240,248,255,1) 0%, white 100%);
    height: 40px
  }
  .fade-out-white{
    background: linear-gradient(0deg, white 0%, white 0%, rgba(240,248,255,1) 100%);
      height: 40px
    }
  

  
  .flip-card {
    background-color: transparent;
    max-width: 300px;
    min-width: 300px;
    height: 370px;
    perspective: 5000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 #343a40;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: white;
    color: black;
  }
  
  .flip-card-back {
    background-color: white;
    color: black;
    transform: rotateY(180deg);
  }
  .call-to-action {
    width: 200px;
    margin: 5px;
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
  }
  .call-to-action:hover {
    background-color: #0056b3;
    transform: scale(1.05); /* Slightly enlarge the button on hover */
  }