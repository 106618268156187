.bl-modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.bl-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 90vh;
    margin: 10px;
    border-radius: 25px;
    background-color: #343a40;
}

.bl-modal-footer {
    padding: 10px;
    text-align: left;
}

.bl-modal-title {
    padding: 10px;
    margin: auto;
    text-align: center;
    color: white;
}

.bl-modal-body {
    padding: 10px;
    background-color: white;
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden;

}

.button{
    text-align:center;
    border-radius: 25px;
    width: 100px;
    background-color: red;
    color: white;
}
